// Placeholder variables are replaced by environment variables ("envsubst < src/config.js" command in the Makefile)
export const cfg = {
  amplify: {
    Auth: {
      mandatorySignIn: true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_ZjJbg2MpX',
      userPoolWebClientId: '1u3shkbq6oeggk1l49e790b4ug',
      identityPoolId: 'us-east-1:137d2bef-7527-458a-9a38-7d951a11d620',
    },
    Storage: {
      region: 'us-east-1',
      bucket: 'prod-aerotage-data',
      identityPoolId: 'us-east-1:137d2bef-7527-458a-9a38-7d951a11d620',
    },
  },
  apiUrl: 'https://ulm67p2w5b.execute-api.us-east-1.amazonaws.com/prod',
};